.layoutContainer {
  height: 100%;
  overflow-x: hidden;
}

.pageContainer {
  margin-left: 40px;
  width: 100%;
  height: 100%;
  margin-top: 16px;

  &.md {
    margin-left: 240px;
    margin-top: 0;
  }

  &.frozen {
    min-width: 100vw;
    overflow: hidden;
  }
}

.pageContainerMobile {
  margin-top: 56px;
  margin-left: 0;
}

.headerContainer {
  display: flex;
  align-items: center;
  height: auto;
  padding-inline: 16px;
  margin: 0 0 16px;

  &.md {
    margin: 12px 0;
    padding-inline: 32px;
  }
}

.contentContainer {
  position: relative;
  padding: 0 16px 16px;
  min-height: auto;

  &.md {
    padding: 16px 32px;
  }
}
