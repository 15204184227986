.wrapper {
  background: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: 100%;
}

.sm {
  padding: 8px;
}

.md {
  padding: 16px;
}

.lg {
  padding: 24px;
}

.xl {
  padding: 32px;
}