$horizontal-padding: 16px;

.layoutContainer {
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: url(../../../../../public/loginBackground.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.headerContainer {
  margin: 16px auto 24px;
  padding: $horizontal-padding;
  max-width: 1280px;
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: space-between;
}

.logoContainer {
  height: 64px;
  min-width: 134px;
}

.contentContainer {
  display: flex;
  justify-content: center;
  padding: 0 $horizontal-padding;
}

.contentWrapper {
  width: 100%;

  &.sm {
    position: absolute;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.formContainer {
  width: 100%;
}
