$open-sidebar-width: 240px;

.sidebar {
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.sidebarMobile {
  width: 100% !important;
  position: fixed !important;
  max-width: initial !important;
  min-width: initial !important;
  height: 40px;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.darkBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-right-width: 1px;
  border-right-style: solid;
  border-bottom: 1px solid rgb(217, 217, 217);
}

.collapsibleSidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 8px;
}

.collapsibleSidebarContainerMobile {
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.logoContainer {
  padding: 16px 32px;
  min-height: 112px;
}

.collapsibleHeaderContainer {
  height: 80px;
  min-height: 80px;
  min-width: $open-sidebar-width;
}

.collapsibleLogoContainer {
  padding-inline: 16px;
  height: 50px;
  min-height: 50px;
}

.menuContainer {
  padding: 8px;
  flex-basis: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.menuWrapper {
  border: none !important;
  background: none;
  min-width: $open-sidebar-width;
}

.viewerPlateContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  min-width: $open-sidebar-width;
}
