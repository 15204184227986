@import "antd/dist/reset.css";

html {
  background: url(../../../public/background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#root,
.ant-app {
  height: 100%;
}

@font-face {
  font-family: "OpenSans";
  src: url(../../../public/OpenSans-Regular.ttf);
}

.tableWrapper {
  height: 0;
  flex: 1;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-inline-start: 1px solid rgba(0, 0, 0, 0.15);
  border-inline-end: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  table {
    width: 100%;
    th {
      text-align: start;
      padding: 8px;
      background-color: #ececec;
      border-inline-end: 1px solid rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    td {
      vertical-align: top;
      padding: 8px;
      border-inline-end: 1px solid rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}
